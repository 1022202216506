import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect, useState } from "react";
import { setCookie, getCookie, hasCookie, deleteCookie } from 'cookies-next';
import Head from 'next/head';
import { Link } from "@/helpers/common_helper";
import { pagelink } from "../../config/site";
import { useForm } from '@mantine/form';
import { SingleLoginTextInput, SingleLoginCheckboxInput } from "@/components/form";
import Subscription from "@/components/section/subscription";
import { _postURL } from "@/api/index";
import { toast } from "@/libraries/swal";
import _ from "lodash";
import { Button, Checkbox, LoadingOverlay, TextInput, Tooltip, UnstyledButton } from '@mantine/core';
import { useScrollLock } from '@mantine/hooks';

export default function Index(props) {
    const { t } = props;
    const [isProcessing, setIsProcessing] = useState(false);
    const [scrollLocked, setScrollLocked] = useScrollLock(false);

    const { getInputProps, onSubmit, isValid } = useForm({
        validateInputOnChange: true,
        initialValues: {
            username: getCookie('remembered-username') ?? '',
            password: '',
            remember: false
        },
        validate: {
            username: (inputValue) => inputValue.length < 1 && 'Username cannot be empty',
            password: (inputValue) => inputValue.length < 8 && 'Password cannot be empty',
        }
    });

    const loginUser = async ({ username, password, remember }) => {
        setIsProcessing(true);
        setScrollLocked(true);

        try {
            const response = await _postURL('/api/auth/login', { username, password });

            if (!_.isObjectLike(response)) throw "Something unexpected happen, please try again later."
            if (_.isEmpty(response.data)) throw response.message;

            if (remember) setCookie('remembered-username', username, { maxAge: "21600" });
            setCookie('user', JSON.stringify({ member: response.data }), { maxAge: "21600" });

            await toast.success(response.message, true, {
                didDestroy: () => { location.pathname = pagelink.user.profile }
            });

        } catch (error) {
            toast.error(error);

        } finally {
            setIsProcessing(false);
            setScrollLocked(false);
        }
    }

    return (
        <>
            <Head>
                <title>{t('Login')} | {t('Site_name')}</title>
            </Head>

            <div className="page-content">
                <section className="register-sec pt-3">
                    <div className="col-9 mx-auto custom_container2">
                        <div className="row">
                            <div className="col-12">
                                <div className="login-form-layout login-form-size">
                                    <h1 className="login-form-layout-title">
                                        {t('Login')}
                                    </h1>
                                    <div className="login-form-layout-message">{t('Dont_have_account')} <a href={pagelink.authentication.signup}>{t('Signup')}</a></div>
                                    <div className="login-inputs">
                                        <form onSubmit={onSubmit((values) => loginUser(values))}>
                                            <LoadingOverlay visible={isProcessing} overlayBlur={1}/>
                                            <TextInput
                                                type='text'
                                                name='username'
                                                placeholder='Username'
                                                classNames={{
                                                    root: `login-input login-input-space`,
                                                    input: `form-control rounded-0 shadow-none`,
                                                    error: `text-end`
                                                }}
                                                styles={{
                                                    input: { height: 'unset' }
                                                }}
                                                { ...getInputProps('username') }
                                            />
                                            <TextInput
                                                type='password'
                                                name='password'
                                                placeholder='Password'
                                                classNames={{
                                                    root: `login-input login-input-space`,
                                                    input: `form-control rounded-0 shadow-none`,
                                                    error: `text-end`
                                                }}
                                                styles={{
                                                    input: { height: 'unset' }
                                                }}
                                                { ...getInputProps('password') }
                                            />
                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                <div className="login__input">
                                                    <Checkbox id='remember' label="Remember Me" name='remember' { ...getInputProps('remember') }/>
                                                </div>
                                                <div className="login__input form-check">
                                                    <label className="form-check-label"><a href="forget-password">{t('Forgot_password_email')}</a></label>
                                                </div>
                                            </div>
                                            <div className='text-center'>
                                                {/*<Button
                                                    type="submit"
                                                    className="btn shadow-none login-btn-pill btn-filled gradient-hover-effect border-0"
                                                    disabled={!isValid()}
                                                    sx={{
                                                        // '&:hover': {
                                                        //     color: 'rgb(16, 41, 69) !important',
                                                        //     borderColor: 'rgb(16, 41, 69) !important'
                                                        // },
                                                    }}
                                                >Log In</Button>*/}
                                                <button
                                                    type="submit"
                                                    className="btn shadow-none login-btn-pill btn-filled gradient-hover-effect border-0"
                                                    disabled={!isValid()}
                                                >{t('Login')}</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="login-info w-100">
                                        *By logging in, you agree to our <a href={pagelink.general.termsAndCondition}>{t('Terms_conditions')}</a> and to receive Ugrado emails & updates and acknowledge that you read our <a target='_blank' href={pagelink.general.privacyPolicy}>{t('Privacy_policy')}</a>. You also acknowledge that {t('Site_name')} uses cookies to give the best user experience.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <section className="login__form ">
                <div className="login__form_layout">
                    <h1 className="login__form_layout_tittle">{t('Login')}</h1>
                    <div className="login__form_layout_message">{t('Dont_have_account')} <Link href={pagelink.authentication.signup}>{t('Signup')}</Link></div>
                    <div className="login__inputs">
                        <form onSubmit={ onSubmit(_submitLoginForm) }>
                            <div className="login__input">
                                <SingleLoginTextInput
                                    name={''}
                                    type={'text'}
                                    placeholder={t('Username')}
                                    props={{ ...getInputProps("username"), id: "username" }}
                                />
                            </div>
                            <div className="login__input">
                                <SingleLoginTextInput
                                    name={''}
                                    type={'password'}
                                    placeholder={t('Password')}
                                    props={{ ...getInputProps("password"), id: "password" }}
                                />
                            </div>
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                <div className="login__input form-check">
                                    <SingleLoginCheckboxInput
                                        label={'Remember Me'}
                                        props={{ ...getInputProps("rememberme", { type: 'checkbox'}) }}
                                    />
                                </div>
                                <div className="login__input form-check">
                                    <label className="form-check-label">
                                        <Link href={pagelink.authentication.forgetPassword}>
                                            <a style={{color:'#2aa7ff'}}>{t('Forgot_password_email')}</a>
                                        </Link>
                                    </label>
                                </div>
                            </div>
                            <button type="submit" className="btn shadow-none login__btn_pill" disabled={buttonIsDisabled}>{t('Login')}</button>
                        </form>
                    </div>
                    <div className="login__info">
                        *By logging in, you agree to our <a href="#">{t('Terms_of_use')}</a> and to receive {t('Site_name')} emails & updates and acknowledge that you read our <Link href={pagelink.general.privacyPolicy}>{t('Privacy_policy')}</Link>. You aslo acknowledge that {t('Site_name')} uses cookies to give the best user experience.
                    </div>
                </div>
            </section> */}

            {/** Subscription Part */}
            <Subscription {...props}/>
        </>
    )
}

export async function getStaticProps(data) {
    return {
        props: {
            ...(await serverSideTranslations(data.locale, ['common'])),
        },
    };
}